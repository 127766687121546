import React from "react";
import { Header, Footer } from "../../layout";
import {
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaGlobe,
  FaFacebook,
} from "react-icons/fa";
import { CardContent, Card, Container, Grid, Typography } from "@mui/material";
const OrganizationInfo = ({ data }) => {
  const { imageUrl, name, socialMediaLinks, description, slogan } = data;
  return (
    <>
      <Header />

      <>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <img
                    src={imageUrl}
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      resizeMode: "contain",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {name}
              </Typography>
              <Typography variant="body1">{slogan}</Typography>
              <div
                style={{
                  display: "flex",
                  marginBottom: "40px",
                  marginTop: "40px",
                  justifyContent: "space-around",
                }}
              >
                {socialMediaLinks.facebook && (
                  <a
                    href={socialMediaLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.twitter && (
                  <a
                    href={socialMediaLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.instagram && (
                  <a
                    href={socialMediaLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.linkedin && (
                  <a
                    href={socialMediaLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.youtube && (
                  <a
                    href={socialMediaLinks.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.other && (
                  <a
                    href={socialMediaLinks.other}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGlobe style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={7} sx={{ mb: 5 }}>
              <Typography variant="description" sx={{}}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </>

      <Footer />
    </>
  );
};

export default OrganizationInfo;
