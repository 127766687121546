import React, { useState } from "react";
import "./Get_Contact_form.scss";
import { Check } from "@mui/icons-material";
import { Input } from "reactstrap";
import { notification } from "antd";
import { Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { sendEmailToClient } from "../components/Contact_Us/_redux/contactUsServices.js/contactUs.service";

export default function Get_Contact_form() {
  const [Spinner, setSpinner] = useState(false);

  const validationSchema = Yup.object({
    from_name: Yup.string()
      .max(50, "Name must be less than 50 characters")
      .required("Name is required"),
    email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email address."
    )
      .required("Email is required"),
    pNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone number is not valid")
      .min(10, "Phone number should be at least 10 digits")
      .max(12, "Phone number should be at most 12 digits")
      .nullable(),
    userOrgination: Yup.string().nullable(),
    message: Yup.string().required("Message is required"),
    Helpmessage: Yup.string().required("Please select an option"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      to_name: "NewFlash",
      Helpmessage: "",
      jobLevel: "",
      from_name: "",
      email: "",
      pNumber: "",
      userOrgination: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (Spinner === true) {
        return;
      }
      setSpinner(true);

      sendEmailToClient(values, (res) => {
        notification.open({
          message: res,
          placement: "top",
          className: "notification",
          closeIcon: <Check />,
          style: {
            width: "200px",
          },
        });
        setSpinner(false); 
      });
    },
    validateOnChange: true, 
    validateOnBlur: true, 
  });

  return (
    <>
      <div className="content-contact mt-5" style={{ textAlign: "right" }}>
        <div style={{ display: "inline-block" }}>
          <div className="content-title title_resp content-contact-title ">
            Get in Contact
          </div>
          <div className="content-title-line content-contact-title-line"></div>
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="contact-body">
              <Input
                name="to_name"
                placeholder="Name*"
                className="input-contact input-item-name d-none"
                value="NewFlash"
                required
              />

              <Input
                id="exampleSelect"
                name="Helpmessage"
                type="select"
                className="select-question input-item-nam"
                required
                onChange={(e) => {
                  formik.setFieldValue("Helpmessage", e.target.value);
                  window.gtag("event", "contact_us_form_Option_select", {
                    event_category: "Contact Us Form",
                    event_label: "Contact Us Form Option Selected",
                    selected_value: e.target.value,
                  });
                }}
              >
                <option value="">How Can We Help?</option>
                <option>Business Inquiry</option>
                <option>General Question</option>
                <option>Comment/Suggestion</option>
                <option>Bug Report</option>
                <option>Custom Data</option>
                <option>Other</option>
              </Input>
              {formik.errors.Helpmessage && formik.touched.Helpmessage ? (
                <div className="error-message">{formik.errors.Helpmessage}</div>
              ) : null}

              <div className="div-contact-two-input-items">
                <Input
                  name="from_name"
                  placeholder="Name*"
                  className="input-contact input-item-name"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.from_name}
                />
                <div className="div-item-space" />

                <Input
                  name="email"
                  placeholder="Email*"
                  className="input-contact input-item-email"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
              <div
                className="errors-container"
                style={{
                  justifyContent:
                    formik.errors.from_name && formik.errors.email
                      ? "space-between"
                      : "flex-end",
                }}
              >
                {formik.errors.from_name && formik.touched.from_name ? (
                  <div className="error-message">{formik.errors.from_name}</div>
                ) : null}

                {formik.errors.email && formik.touched.email ? (
                  <div className="error-message">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="div-contact-two-input-items">
                <Input
                  name="pNumber"
                  placeholder="Phone"
                  className="input-contact input-item-phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pNumber}
                />

                <div className="div-item-space" />

                <Input
                  name="userOrgination"
                  placeholder="Organization"
                  className="input-contact input-item-organize"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userOrgination}
                />
              </div>

              <div
                className="errors-container"
                style={{
                  justifyContent:"space-between"
                }}
              >
                {formik.errors.pNumber && formik.touched.pNumber ? (
                  <div className="error-message">{formik.errors.pNumber}</div>
                ) : null}

                {formik.errors.userOrgination &&
                formik.touched.userOrgination ? (
                  <div className="error-message">
                    {formik.errors.userOrgination}
                  </div>
                ) : null}
              </div>

              <Input
                name="message"
                placeholder="Message*"
                className="input-contact-message"
                type="textarea"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
              />
              {formik.errors.message && formik.touched.message ? (
                <div className="error-message">{formik.errors.message}</div>
              ) : null}

              <button type="submit" className="contact-button">
                {Spinner === true ? "waiting..." : "Send"}
              </button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </>
  );
}
