import React, { useState } from "react";
import { Button, Drawer, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { Nav } from "reactstrap";
import { FaRegUserCircle } from "react-icons/fa";
import { MenuProps } from "antd";
import { useSelector } from "react-redux";
import { User_Data, updateAuth } from "../../../redux/Slices/UserAuth";
import { useDispatch } from "react-redux";

export default function Offcanvas_nav({ onClose, open, Landing_page }) {
  const isuser = useSelector((state) => state.UserAuth.isAuth);
  const history = useNavigate();
  const dispatch = useDispatch();
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="link_drop_down">
          <Link
            to="/User/Settings"
            className="nav_link"
            onClick={() => onClose()}
          >
            <a className="Drop_down_menu_items" rel="noopener noreferrer">
              Settings
            </a>
          </Link>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="link_drop_down">
          <a
            className="Drop_down_menu_items"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => (
              dispatch(updateAuth({ isAuth: false })),
              dispatch(User_Data({}), history("/"))
            )}
          >
            Sign Out
          </a>
        </div>
      ),
    },
  ];
  let url = window.location.pathname;

  const NavItem = ({ to, onClose, children }) => (
    <li className="nav-item">
      <a className="nav-link">
        <Link className="nav_link" to={to} onClick={onClose}>
          {children}
        </Link>
      </a>
    </li>
  );

  const UserDropdown = ({ items }) => (
    <li className="nav-item">
      <a className="nav-link">
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          arrow
          overlayClassName="change_bg"
        >
          <div className="nav_link nav_login">
            <FaRegUserCircle className="User_login_icon" />
          </div>
        </Dropdown>
      </a>
    </li>
  );

  const LoginSignup = ({ to, onClose, children }) => (
    <li className="nav-item">
      <Link to={to} className="nav_link" onClick={onClose}>
        <a className="nav-link">
          <div className="nav_link nav_login">
            <span
              style={
                to === "/signup"
                  ? {
                      border: "1px solid white",
                      borderRadius: "10px",
                      padding: "10px 15px 10px 15px",
                    }
                  : {}
              }
            >
              {children}
            </span>
          </div>
        </a>
      </Link>
    </li>
  );

  return (
    <div>
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={open}
        className="drawe_bg"
      >
        <div aria-hidden="true">
          <div className="modal-dialog dialog-animated">
            <div className="modal-content h-100">
              <div className="menu modal-body">
                <div className="row w-100">
                  <div className="items p-0 col-12 text-center">
                    <ul className="navbar-nav items mx-auto text-white">
                      <NavItem to="/" onClose={onClose}>
                        Home
                      </NavItem>
                      <NavItem to="/Partners" onClose={onClose}>
                        Partners
                      </NavItem>
                      <NavItem to="/blogs" onClose={onClose}>
                        Blogs
                      </NavItem>
                      <NavItem
                        to={
                          Landing_page !== "First_Page"
                            ? "/User/About_Us"
                            : "/About_Us"
                        }
                        onClose={onClose}
                      >
                        About Us
                      </NavItem>
                      <NavItem
                        to={
                          Landing_page !== "First_Page"
                            ? "/User/Contact_Us"
                            : "/Contact_Us"
                        }
                        onClose={onClose}
                      >
                        Contact Us
                      </NavItem>
                      <NavItem
                        to={
                          Landing_page !== "First_Page"
                            ? "/User/archive"
                            : "/archive"
                        }
                        onClose={onClose}
                      >
                        Archive
                      </NavItem>
                      {isuser && (
                        <NavItem to="/User/DashBoard" onClose={onClose}>
                          User DashBoard
                        </NavItem>
                      )}
                      {isuser ? (
                        <UserDropdown items={items} />
                      ) : (
                        <>
                          <LoginSignup to="/login" onClose={onClose}>
                            Log In
                          </LoginSignup>
                          <LoginSignup to="/signup" onClose={onClose}>
                            Sign up
                          </LoginSignup>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
