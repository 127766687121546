import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../layout";
import "./Blogs.css";
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useFetchBlogs from "./useFetchBlogs";
import MenuItem from "@mui/material/MenuItem";
import { Skeleton } from "antd";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

const categories = [
  "All",
  "Science & Tech",
  "Law and Crime",
  "Lifestyle & Health",
  "Politics",
  "Biz & Finance",
  "Entertainment",
  "Sports",
  "Gaming",
];

const Blogs = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const { blogsList, isLoading } = useFetchBlogs(selectedCategory, page);

  const handleNavigate = (blog) => {
    navigate(`/blogs/${blog._id}`, {
      state: {
        blog: blog,
      },
    });
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setPage(1); // Reset pagination
  };

  const handleSearch = (event) => {
    if (event.key === "Enter" || !event.target.value) {
      setSearch(event.target.value);
      setPage(1);
    }
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredBlogs = blogsList.filter(
    (blog) =>
      (blog.title && blog.title.toLowerCase().includes(search.toLowerCase())) ||
      (blog.content &&
        blog.content.toLowerCase().includes(search.toLowerCase()))
  );
  const imgStyle = {
    objectFit: "cover",
    borderRadius: "10px",
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="Container1">
        <Header />

        {/* Main Container */}
        <Container maxWidth="md">
          {/* Blob and Title Container */}
          <Box sx={{ position: "relative", textAlign: "left", margin: "5%" }}>
            <Box
              sx={{
                position: "absolute",
                width: "15vw",
                height: "90%",
                background:
                  "linear-gradient(45deg, rgba(255,0,0,0.5), rgba(0,0,255,0.5))",
                filter: "blur(40px)",
              }}
            />
            <Box sx={{ padding: "20px" }}>
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                Blog
              </Typography>
              <Typography>Our Thoughts and Findings</Typography>
            </Box>
          </Box>

          {/* Center-Container */}
          <Box
            className="C4"
            sx={isSmallScreen ? { marginTop: "0px" } : { marginTop: "4rem" }}
          >
            {/* Blogs Container / Grid */}
            <Box
              className="BlogsContainer"
              sx={{
                maxWidth: "500px",
                overflowY: "scroll",
                // borderRight: "3px solid lightgrey ",
              }}
            >
              <Grid container spacing={3}>
                {isLoading ? (
                  [...Array(3)].map((_, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper
                        elevation={2}
                        style={{
                          padding: "15px",
                          height: "160px",
                          width: "200px",
                          cursor: "pointer",
                        }}
                        variant="outlined"
                      >
                        <Skeleton variant="rect" height={160} width={200} />
                      </Paper>
                    </Grid>
                  ))
                ) : filteredBlogs.length === 0 ? (
                  <Typography
                    variant="h4"
                    sx={{ textAlign: "center", padding: "8px", mt: 3, ml: 2 }}
                  >
                    No Blogs Found
                  </Typography>
                ) : (
                  filteredBlogs.map((blog, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper
                        elevation={1}
                        style={{
                          minHeight: "200px",
                          height: "auto",
                          width: "200px",
                          cursor: "pointer",
                          marginBottom: "18px",
                          borderRadius: "10px",
                        }}
                        variant="elevation"
                        onClick={() => handleNavigate(blog)}
                      >
                        <Box key={index} sx={{ fontWeight: "bold" }}>
                          <img
                            style={imgStyle}
                            src={
                              blog?.coverImage
                                ? blog?.coverImage
                                : "https://images.pexels.com/photos/768474/pexels-photo-768474.jpeg?auto=compress&cs=tinysrgb&w=600"
                            }
                            alt=""
                            height="100px"
                            width="100%"
                          />
                          <Typography
                            variant="h6"
                            sx={{ wordWrap: "break-word", mt: 1 }}
                          >
                            {blog.title}
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  ))
                )}
              </Grid>
            </Box>

            {/* Divider */}
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                width: "3px",
              }}
            />

            {/* SideBar - Search and rest. */}
            <Box className="sideViewC6">
              {/* SearchBar */}
              <Box style={{ marginBottom: "20px" }}>
                <Paper
                  style={{
                    borderRadius: "20px",
                    padding: "5px 15px",
                    display: "flex",
                    alignItems: "center",
                    borderColor: "black",
                  }}
                  variant="outlined"
                >
                  <Box ml={1} style={{ cursor: "pointer" }}></Box>
                  <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Search..."
                    type="text"
                    InputProps={{ disableUnderline: true }}
                    onKeyDown={handleSearch}
                    onChange={handleSearchChange}
                  />
                </Paper>
              </Box>

              {/* HighLighted Post */}
              <Box className="highlightPost">
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                  varient="outlined"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Highlighted Post:
                  </Typography>
                  <Typography variant="body2">
                    This is the highlighted post content.
                  </Typography>
                </Box>
              </Box>

              {/* Categories */}
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "left",
                    fontWeight: "bold",
                    paddingBottom: "15px",
                    marginTop: isSmallScreen ? "0%" : "45%",
                  }}
                >
                  Categories
                </Typography>
                <Box className="categories">
                  {isSmallScreen ? (
                    <TextField
                      variant="outlined"
                      select
                      label="Select Category"
                      labelId="categories-select-label"
                      id="categories-select"
                      value={selectedCategory}
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      sx={{
                        cursor: "pointer",
                        marginBottom: "5px",
                        width: "100%",
                        color: "#666666",
                      }}
                    >
                      {categories.map((category, index) => (
                        <MenuItem value={category} key={index}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    categories.map((category, index) => (
                      <span
                        onClick={() => handleCategoryChange(category)}
                        key={`category_${index}`}
                      >
                        <Typography
                          variant="body2"
                          key={index}
                          sx={{
                            cursor: "pointer",
                            marginBottom: "5px",
                            color:
                              category === selectedCategory
                                ? "#b642b6"
                                : "#666666",
                          }}
                          className={
                            category === selectedCategory
                              ? "blogCategory selected"
                              : "blogCategory"
                          }
                        >
                          {category}
                        </Typography>
                      </span>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Blogs;
