import { useState, useEffect } from "react";
import axios from "axios";
import { apiUrl } from "../../config";

export const useVerifyRecaptcha = () => {
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  useEffect(() => {
    const verifyRecaptcha = async () => {
      if (!recaptchaToken) return;
      try {
        const response = await axios.post(`${apiUrl}/api/v1/verifyRecaptcha`, {
          recaptchaToken,
        });
        setIsRecaptchaVerified(response.data.success);
      } catch (error) {
        console.error("Recaptcha verification failed:", error);
        setRecaptchaToken("");
        setIsRecaptchaVerified(false);
      }
    };

    verifyRecaptcha();
  }, [recaptchaToken]);

  return {
    onRecaptchaChange: setRecaptchaToken,
    isRecaptchaVerified: isRecaptchaVerified,
  };
};
