//uncomment api url and local ur of backend.news.... while deploying

// export const apiUrl = "http://localhost:4500";
export const apiUrl = "https://backend.newsflow.io";
// export const apiUrl = "http://13.48.203.109/backend";
//export const apiUrl = process.env.REACT_APP_API_PATH
// export const loacalURL = "https://newflash.womenempowerment.online/api/v1/";
// export const loacalURL = "http://localhost:4500";
 export const loacalURL = "https://backend.newsflow.io";
// export const loacalURL = "http://localhost:4500";
