import { getAllUsersWithoutPagination, updateUser } from "./_redux/userActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { BiEdit } from "react-icons/bi";
import ComponentLoader from './../../../components/shared/loader/ComponentLoader';
import SearchBox from "../../components/searchComponent/SearchComponent";
import { useNavigate } from "react-router-dom";
import "./styles.css"

const UsersDetail = () => {

  const { entities } = useSelector(
    (state) => state?.userManagementSlice,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);
  const UserInfo = useSelector((state) => state.UserAuth.UserInfo,shallowEqual);
  const navigate = useNavigate();

  const hasAdminAccess = useMemo(() => {
    return UserInfo?.isAdmin ? true : false;
  }, [UserInfo]);

  useEffect(() => {
    if (!hasAdminAccess) {
      navigate('/'); // Redirect to home page
    }
  }, [hasAdminAccess, navigate]);
// Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Start on page 1
  const usersPerPage = 15; // Number of users to show per page

  useEffect(() => {
    dispatch(getAllUsersWithoutPagination());
  }, [shouldReload, dispatch]);

  const handleUpdateUser = (userId, isPrevillagedUser) => {
    setIsLoading(true);
    dispatch(
      updateUser(userId, { isPrevillagedUser }, () => {
        setIsLoading(false);
        setShouldReload((prev) => !prev);
      })
    );
  };

  // Get current users for the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = entities?.length>0&&entities?.slice(indexOfFirstUser, indexOfLastUser);
  // Calculate total pages
  const totalPages = Math.ceil(entities?.length / usersPerPage);

  // Handle pagination controls
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } 
  };

  return (
<div className="userContainer">
  {isLoading && <ComponentLoader />}
  <SearchBox />
  <div className="table-container">
    <div className="overflow-table">
      <table className="user-table">
        <thead className="table-header">
          <tr>
            <th className="table-cell">Name</th>
            <th className="table-cell">Email</th>
            <th className="table-cell">User Type</th>
            <th className="table-cell">Actions</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {currentUsers?.length > 0 && currentUsers?.map((person, index) => (
            <tr key={person?._id} className={`table-row ${index % 2 ? 'row-hover' : 'row-default'}`}>
              <td className="table-cell">{person?.UserName}</td>
              <td className="table-cell">
                <p className="truncate">{person.email}</p>
              </td>
              <td className="table-cell">
                {person?.isPrevillagedUser ? "Privileged User" : "Basic User"}
              </td>
              <td className="table-cell-center">
                <button
                  className={`action-button ${person?.role?.slug === "Super_Admin_Slug" ? 'disabled' : 'enabled'}`}
                  onClick={() => {
                    if (person?.role?.slug !== "Super_Admin_Slug") {
                      handleUpdateUser(person?._id, !person?.isPrevillagedUser);
                    }
                  }}
                  disabled={person?.role?.slug === "Super_Admin_Slug"}
                >
                  <BiEdit className="icon" title="Grant/Revoke privilege" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    {/* Pagination Controls */}
    {entities?.length > usersPerPage && (
      <div className="pagination-controls">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className={`pagination-button ${currentPage === 1 ? 'disabled' : 'enabled'}`}
        >
          Previous
        </button>
        <span className="page-info">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`pagination-button ${currentPage === totalPages ? 'disabled' : 'enabled'}`}
        >
          Next
        </button>
      </div>
    )}
  </div>
</div>

  );
};

export default UsersDetail;
