import { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../config";

export const useSignupForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");

  const onFinish = async (values, successCallback) => {
    window.gtag("event", "sign_up_form_submit", {
      event_category: "Sign Up Form",
      event_label: "Sign Up Form Submit Success",
    });

    setIsLoading(true);
    let res = await axios.post(apiUrl + "/api/v1/sendOTP", {
      UserName: values.UserName,
      email: values.email,
      password: values.password,
    });

    if (!!res.data.success) {
      setEmail(values.email);
      toast.success(res.data.msg);
      setIsLoading(false);
      successCallback();
    } else {
      toast.error(res.data.msg);
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    window.gtag("event", "sign_up_form_submit_fail", {
      event_category: "Sign Up Form",
      event_label: "Sign Up Form Submit Failed",
    });
  };

  const verifyOTP = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUrl}/api/v1/VarifyOTP`, {
        email,
        otp,
      });
      if (response.data.success) {
        toast.success("Verification successful");
        navigate("/login");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      toast.error("Verification failed. Please try again.");
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    email,
    setEmail,
    otp,
    setOtp,
    onFinish,
    verifyOTP,
    onFinishFailed,
  };
};
