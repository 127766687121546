import React from "react";

function ImportanceReasonItem({ article, pitimeframe }) {
  if (!article) return null;
  console.log(pitimeframe);
  return (
    <>
      <h4 className="titleArtical">{article.title}</h4>
      <ul>
        {[article.primaryArtical, ...article.secondaryArticals].map(
          (article, index) => {
            return (
              <li className="pb-4" key={`importanceReasoning_${index}`}>
                <span className="headerbox_Second">
                  {article?.title}
                  {article?.score?.[pitimeframe]?.score && (
                    <> ({article.score[pitimeframe].score}/100 relevance)</>
                  )}
                </span>
                {article?.score?.[pitimeframe]?.reason && (
                  <span>{article.score[pitimeframe].reason}</span>
                )}
              </li>
            );
          }
        )}
      </ul>
    </>
  );
}

export default ImportanceReasonItem;
