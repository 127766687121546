import React, { useEffect, useState } from "react";
import "./Carousel_NewsFlash.css";
import { Carousel } from "antd";
import FAQ from "../components/About_Us/FAQ";
import { apiUrl } from "../../config";
import axios from "axios";

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  paddingTop: "3rem",
};

export default function Carousel_NewsFlash() {
  const [faqs, setFaqs] = useState([]);
  const fetchFaqs = async() => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/Faq`);
      setFaqs(response.data)
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFaqs();
  }, []);
  return (
    <div>
      <div className="content-qna-item-active">
        <Carousel>
          {faqs.length > 0 && faqs?.map((faq) => (
            <FAQ title={faq.title} content={faq.content} key={faq._id}/>
          ))}
        </Carousel>
      </div>
      <div className="content-qna">
        {/* <div className="content-qna-select">
          <div className="conetnt-qna-slecet-item select-first-qna conetnt-qna-slecet-item-active"></div>
          <div className="conetnt-qna-slecet-item select-second-qna"></div>
          <div className="conetnt-qna-slecet-item select-third-qna"></div>
        </div> */}
      </div>
    </div>
  );
}
