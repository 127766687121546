import axios from "axios";
import { apiUrl } from "../../../config";
import {useState, useEffect} from 'react';

const useFetchBlogs = (category, page) => {
  const [blogsList, setBlogsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const queryCategory = category === "All" ? "" : category;
        const { data } = await axios.get(
          `${apiUrl}/api/v1/blogs?&category=${queryCategory}&page=${page}`
        );
        setBlogsList(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setBlogsList([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [category, page]);

  return { blogsList, isLoading };
};

export default useFetchBlogs;
