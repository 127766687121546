import React, { useState } from "react";
import { Button, Form, Modal, Select } from "antd";
import { useFormik, FormikProvider } from "formik";
import { options } from "./Options";
import axios from "axios";
import { apiUrl } from "../../../config";
import toast from "react-hot-toast";
export default function Model_steps({ setIsModalOpen, isModalOpen }) {
  const [count, setcount] = useState(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendDataToServer = async (values) => {
    console.log(values)
    try {
      await axios.post(`${apiUrl}/api/v1/model`, values);
      toast.success("Thanks for the Feedback!");
    } catch (err) {
      toast.error("Failed to submit data");
      console.log(err);
    }
  };

  const onFormLayoutChange = ({ size }) => {};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      industry: "",
      jobDescription: "",
      jobLevel: "",
      whereYouHeared: [],
      topThreeChoices: [],
    },
    onSubmit: (values) => {
      sessionStorage.setItem('Ismodal',true);
    },
    // validationSchema:ModalStepsValidation
  });
  const handleCheckboxChange = (e, fieldName) => {
    const { value, checked } = e.target;
    const fieldValue = formik.values[fieldName];

    if (checked) {
      // Add the selected value
      formik.setFieldValue(fieldName, [...fieldValue, value]);
    } else {
      // Remove the unselected value
      formik.setFieldValue(
        fieldName,
        fieldValue.filter((item) => item !== value)
      );
    }
  };
  return (
    <div>
      <Modal
        className="model_main"
        footer={null}
        width="40%"
        closable={false}
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <FormikProvider value={formik}>
          <Form onFinish={() => formik.handleSubmit()}>
            {count == 0 ? (
              <div className="model_main_div">
                <p className="first_p">Just a few more things</p>
                <p>Your Profession:</p>
                <div className="checkbox_model">
                  <div className="inner_div_model_checkbox1 ">
                    <Form.Item
                      label={<span className="Steps_labels">Industry</span>}
                    >
                      <Select
                        name="industry"
                        // style={{ width: "300px" }}
                        placeholder="Select..."
                        options={options?.optionsForIndustry}
                        onChange={(e) => {
                          formik?.setFieldValue("industry", e);
                          window.gtag("event", "industry_select", {
                            event_category: "More User Info",
                            event_label: "Industry Selected",
                            selected_value: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="Steps_labels">Job Des...</span>}
                    >
                      <Select
                        name="jobDescription"
                        placeholder="Select..."
                        // style={{ width: "300px" }}
                        options={options?.optionsForJob}
                        onChange={(e) => {
                          formik?.setFieldValue("jobDescription", e);
                          window.gtag("event", "job_des__select", {
                            event_category: "More User Info",
                            event_label: "Job Des Selected",
                            selected_value: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="Steps_labels">Job Level</span>}
                    >
                      <Select
                        placeholder="Select..."
                        name="jobLevel"
                        // style={{ width: "300px" }}
                        options={options?.optionsForJobLevel}
                        onChange={(e) => {
                          formik?.setFieldValue("jobLevel", e);
                          window.gtag("event", "job_level_select", {
                            event_category: "More User Info",
                            event_label: "Job Level Selected",
                            selected_value: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div className="model_footer_Things">
                        <span className="me-5">1/3</span>
                      </div>
                      <div className="model_footer_Things">
                        <button
                          className="btn"
                          onClick={() => setcount(count + 1)}
                        >
                          Next  
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : count == 1 ? (
              <div className="model_main_div">
                <p className="first_p">Almost There!</p>
                <p>Where did you hear of NewsFlow?</p>
                <div className="checkbox_model">
                  <div className="inner_div_model_checkbox">
                    {options?.optionsForCheckBoxes?.map((checkBox) => (
                      <div>
                        <input
                          type="checkbox"
                          name="whereYouHeared"
                          id=""
                          value={checkBox?.name}
                          onChange={(checkBox) => {
                            window.gtag("event", "checkbox_select", {
                              event_category: "More User Info",
                              event_label: "Check Box Selected",
                              selected_value: checkBox?.name,
                            });
                            handleCheckboxChange(checkBox, "whereYouHeared");
                          }}
                          className="me-2"
                        />{" "}
                        <span>{checkBox?.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="model_footer_Things">
                    <span className="me-5">2/3</span>
                    <button className="btn" onClick={() => setcount(count - 1)}>
                      Back
                    </button>
                  </div>
                  <div className="model_footer_Things">
                    <button className="btn" onClick={() => setcount(count + 1)}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="model_main_div">
                <p className="first_p">News Interests:</p>
                <p>Pick you top 3 news categories</p>

                <div className="checkbox_model">
                  <div className="checkbox_model">
                    <div className="inner_div_model_checkbox">
                      {options?.newsInterestCheckBoxes?.map((checkBox) => (
                        <div>
                          <input
                            type="checkbox"
                            name="topThreeChoices"
                            id=""
                            value={checkBox?.name}
                            onChange={(checkBox) => {
                              window.gtag("event", "top_3_news_cat_select", {
                                event_category: "More User Info",
                                event_label: "Top 3 News Categories Selected",
                                selected_value: checkBox?.name,
                              });
                              handleCheckboxChange(checkBox, "topThreeChoices");
                            }}
                            className="me-2"
                          />{" "}
                          <span>{checkBox?.label}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div className="model_footer_Things">
                    <span className="me-5">3/3</span>
                    <button className="btn" onClick={() => setcount(count - 1)}>
                      Back
                    </button>
                  </div>
                  <div className="model_footer_Things">
                    <button
                      className="btn"
                      type="submit"
                      onClick={() => {
                        handleOk();
                        sendDataToServer(formik.values)
                        window.gtag("event", "more_user_info_form_submit", {
                          event_category: "More User Info",
                          event_label: "More User Info Form Submitted",
                        });
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Form>
        </FormikProvider>
      </Modal>
    </div>
  );
}
