import { Box, Container, Icon, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Footer, Header } from "../../layout";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import { apiUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import "./BlogDetails.css";
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

function BlogDetails() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [blogsList, setBlogsList] = useState([]);
  const [blog, setBlog] = useState({
    title: "",
    date: "",
    sections: [],
  });

  const handleNavigate = (blog) => {
    window.scrollTo({
      top:  0,
      left:  0,
      behavior:'smooth',
     });
    navigate(`/blogs/${blog._id}`, {
      state: {
        blog: blog,
      },
    });
  };

  const fetchAllBlogs = async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/blogs`);
    return data;
  };
  const imgStyle = {
    objectFit: "cover",
  };
  const fetchBlogById = async (id) => {
    const { data } = await axios.get(`${apiUrl}/api/v1/blogs/${id}`);
    return data;
  };

  useEffect(() => {
    (async () => {
      const blogs = await fetchAllBlogs();
      setBlogsList(blogs);
    })();
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const id = pathname.split("/")[2];

  useEffect(() => {
    (async () => {
      if (location.state && location.state.blog) {
        setBlog(location.state.blog);
      } else {
        const fetchedBlog = await fetchBlogById(id);
        setBlog(fetchedBlog);
      }
    })();
  }, [location.state, id]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="C1" sx={{ minHeight: "100vh" }}>
        <Header />
        <Container className="C2" maxWidth="lg">
          {/* Title */}
          <Box sx={{ position: "relative", textAlign: "left", margin: "5%" }}>
            <Box
              sx={{
                position: "absolute",
                width: "15vw",
                height: "90%",
                background:
                  "linear-gradient(45deg, rgba(255,0,0,0.5), rgba(0,0,255,0.5))",
                filter: "blur(40px)",
              }}
            />
            <Box sx={{ padding: "20px" }}>
              <span>
                <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                  {blog.title}
                </Typography>
              </span>
              <span>
                <Typography>
                  {blog.category ?? "N/A"} |{" "}
                  {new Date(blog.date).toLocaleDateString()}
                </Typography>
              </span>
            </Box>
          </Box>
          <div dangerouslySetInnerHTML={{ __html: blog.html }} />

          {/* Previous Post Heading */}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{ position: "relative", textAlign: "center", margin: "5%" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "15vw",
                  height: "90%",
                  background:
                    "linear-gradient(45deg, rgba(255,0,0,0.5), rgba(0,0,255,0.5))",
                  filter: "blur(40px)",
                }}
              />
              <Box sx={{ padding: "20px" }}>
                <Typography
                  variant={isSmallScreen ? "h5" : "h4"}
                  sx={{ fontWeight: "bold" }}
                >
                  Previous Posts :
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Prev Post Tray */}
          <Box
            sx={{
              maxWidth: isSmallScreen ? "100vw" : "75vw",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              overflowX: "scroll",
              alignSelf: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {blogsList.map((blog, index) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  cursor:'pointer'
                }}
                onClick={() => handleNavigate(blog)}
              >
                <Box key={index} sx={{ padding: "5px", fontWeight: "bold" }}>
                  <img
                    style={imgStyle}
                    src={blog?.coverImage ? blog?.coverImage : "https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"}
                    alt=""
                    height="200px"
                    width="250px"
                  />
                  <Typography variant="h6">{blog.title}</Typography>
                  <Typography variant="subtitle2">{blog.author}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default BlogDetails;
