import { useEffect, useState } from "react";
import RangeSlider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { useSelector } from "react-redux";

const Slider = ({ setDensity_error, setValue }) => {
  const [value_slider, setValue_slider] = useState(50);
  const [left, setLeft] = useState(50);
  const isuser = useSelector((state) => state.UserAuth.isAuth);

  const handleChange = (e) => {
    if (isuser) {
      const data = e.target.value;
      setValue_slider(data);
      setValue(data / 5);
      setLeft(data);

      window.gtag("event", "slider_value_change", {
        event_category: "Slider",
        event_label: `Slider Value Changed: ${data}`,
      });
    }
  };

  return (
    <div
      className="slider"
      style={{ cursor: isuser ? "pointer" : "not-allowed" }}
    >
      <div className="range-container">
        <label
          htmlFor="range"
          style={{
            left: `${value_slider === 100 ? value_slider : left - 8}%`,
            marginTop: "1rem",
          }}
        >
          {value_slider}
        </label>
        <input
          defaultValue={50}
          type="range"
          name="range"
          onChange={handleChange}
          id="range"
          min="10"
          max="100"
          step={10}
          onClick={() => setDensity_error(true)}
          disabled={!isuser}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>10%</div>
        <div>100%</div>
      </div>
    </div>
  );
};

export default Slider;