// src/views/NotFound.js
import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/Header";
import "./notfound.css";
const NotFound = () => {
  return (
    <>
      <Header />
      <div className="not-found">
        <h1 className="error-text-heading">404</h1>
        <p className="error-text-paragraph">Oops, we can't find that page</p>
        <div className="returnbtn">
        <Link to="/" className="returnbtntext">Return to Home Page</Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
