import React, { useEffect, useState } from "react";
import "./About_Us.css";
import { Footer, Header } from "../../layout";
import User_DashBoard_Header from "../User_DashBoard_Header/User_DashBoard_Header";
import { useCollapse as expandReference, useCollapse } from "react-collapsed";
import FAQ from "./FAQ";
import axios from "axios";
import { apiUrl } from "../../../config";
import { Typography } from "@mui/material";

export default function About_Us() {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const {
    getCollapseProps: getCollapseProps1,
    getToggleProps: getToggleProps1,
    isExpanded: isExpanded1,
  } = expandReference();
  const {
    getCollapseProps: getCollapseProps2,
    getToggleProps: getToggleProps2,
    isExpanded: isExpanded2,
  } = expandReference();
  const {
    getCollapseProps: getCollapseProps3,
    getToggleProps: getToggleProps3,
    isExpanded: isExpanded3,
  } = expandReference();

  let url = window.location.pathname;
  const [faqs, setFaqs] = useState([]);
  const fetchFaqs = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/Faq`);
      setFaqs(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchFaqs();
  }, []);
  return (
    <>
      {url == "/About_Us" ? <Header /> : <></>}

      <div
        className="About_bg"
        style={{ paddingTop: url == "/User/About_Us" ? "5rem" : "" }}
      >
        <div className="container">
          {faqs.length > 0 ? (
            faqs.map((faq) => {
              return (
                <>
                  <FAQ title={faq.title} content={faq.content} />
                  <div className="faq_spliter">
                    <div style={{ backgroundColor: "#000" }}></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <Typography variant="h4" align="left">
                Loading...
              </Typography>
            </>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}
