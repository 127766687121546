import { createSlice } from "@reduxjs/toolkit";

const initialUserManagementState = {
  actionLoading: false,
  listLoading: false,
  permissions: [],
  roles: [],
  selectedUser: null,
  selectedPermission: null,
  selectedRole: null,
  entities: [],
  errorResponse: null,
  totalPages:0
};

export const userManagementSlice = createSlice({
  name: "userManagementSlice",
  initialState: initialUserManagementState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload === "action") {
        state.actionLoading = true;
      } else {
        state.listLoading = true;
      }
    },
    stopCall: (state, action) => {
      if (action.payload === "action") {
        state.actionLoading = false;
      } else {
        state.listLoading = false;
      }
    },
    getAllPermissions: (state, action) => {
      state.permissions = action.payload.data.data.permissions;
    },
    getAllRoles: (state, action) => {
      state.roles = action.payload.data.data.roles;
      state.listLoading = false;
    },
    setSelectedUser: (state, action) => {
      const { user } = action.payload;
      state.selectedUser = user;
      state.actionLoading = false;
    },
    setSelectedPermission: (state, action) => {
      const { permission } = action.payload;

      state.selectedPermission = permission;
      state.actionLoading = false;
    },
    setSelectedRole: (state, action) => {
      const { role } = action.payload;
      state.selectedRole = role;
      state.actionLoading = false;
    },
    setErrorResponse: (state, action) => {
      const { message } = action.payload;
      state.errorResponse = message;
    },
    cleanUpErrorResponse: (state, action) => {
      state.errorResponse = null;
    },
    saveUsers: (state, action) => {
      

      state.entities = action?.payload?.data?.data;
     
      // const { limit, currentPage, totalPages, totalCount } = action.payload.data.data;
      // state.limit = limit;
      // state.totalPages = totalPages;
      // state.totalResults = totalCount;
      // state.page = currentPage;
      state.listLoading = false;
     
    },
    cleanUpUserSelectedUser: (state, action) => {
      state.selectedUser = null;
    },
  },
});