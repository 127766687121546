import { Button } from "reactstrap";

export const GoogleLoginButton = ({ onClick }) => (
  <Button
    color="light"
    className="google-login-button"
    onClick={onClick}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "64px",
      padding: "10px 20px",
      width: "100%",
      marginBottom: "12px",
      justifyContent: "space-between",
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6 10.2273C19.6 9.51825 19.5364 8.83643 19.4182 8.18188L10 8.18188L10 12.0501L15.3818 12.0501C15.15 13.3001 14.4455 14.3592 13.3864 15.0682L13.3864 17.5773L16.6182 17.5773C18.5091 15.8364 19.6 13.2728 19.6 10.2273Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99984 20C12.6998 20 14.9635 19.1046 16.618 17.5773L13.3862 15.0682C12.4907 15.6682 11.3453 16.0228 9.99984 16.0228C7.39529 16.0228 5.19075 14.2637 4.40439 11.9L1.06348 11.9L1.06348 14.4909C2.70893 17.7591 6.09075 20 9.99984 20Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.40455 11.8999C4.20455 11.2999 4.09091 10.659 4.09091 9.99994C4.09091 9.34085 4.20455 8.69994 4.40455 8.09994L4.40455 5.50903L1.06364 5.50903C0.386364 6.85903 3.70053e-10 8.38631 5.77586e-10 9.99994C7.8512e-10 11.6136 0.386364 13.1409 1.06364 14.4909L4.40455 11.8999Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99984 3.97727C11.468 3.97727 12.7862 4.48182 13.8226 5.47273L16.6907 2.60455C14.9589 0.990909 12.6953 -1.496e-09 9.99984 -1.14933e-09C6.09075 -6.46569e-10 2.70893 2.24091 1.06348 5.50909L4.40439 8.1C5.19075 5.73636 7.39529 3.97727 9.99984 3.97727Z"
        fill="#EA4335"
      />
    </svg>
    <span>Sign in with Google</span>
    <div style={{ width: 20, height: 20 }} />
  </Button>
);
