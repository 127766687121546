import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PartnerInfo from "./PartnerInfo";

const PartnerDetail = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    const fetchPartner = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/api/v1/partners/${id}`
      );
      setPartner(response?.data);
    };

    fetchPartner();
  }, [id]);

  if (!partner) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <PartnerInfo data={partner} />
    </div>
  );
};

export default PartnerDetail;
