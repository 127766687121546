import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch } from "react-redux";
import { getAllUsersWithoutPagination } from './../../Admin_DashBoard/users/_redux/userActions';

const SearchBox = () => {
  const [searchInput, setSearchInput] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchClick = () => {
    setShowSearch(!showSearch); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(getAllUsersWithoutPagination({
      query: searchInput, // Sending the search input to the backend
    }));
  };

  const handleReset = () => {
    setSearchInput(''); // Clear the input
    dispatch(getAllUsersWithoutPagination({ query: '' })); // Reset the search results
  };

  return (
    <div className="p-4 border border-gray-300 rounded-lg w-full mx-auto mt-16">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">Type a name or email to locate users quickly </h2>
        <FaSearch className="text-gray-500 cursor-pointer w-10 h-10" onClick={handleSearchClick} />
      </div>
      {showSearch && (
        <form onSubmit={handleSubmit} className="mt-4 flex flex-col space-y-3">
          <input
            type="text"
            placeholder="Search by email, name, or role"
            value={searchInput}
            onChange={handleInputChange}
            className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex  justify-end space-x-3">
          <button
              type="button"
              onClick={handleReset}
              className="p-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors duration-200"
            >
              Reset
            </button>
            <button
              type="submit"
              className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              Search
            </button>

          </div>
        </form>
      )}
    </div>
  );
};

export default SearchBox;
