import React from "react";
import ArticlesBoard from "../ArticlesBoard/ArticlesBoard";

export default function User_Tab() {
  return (
    <div>
      <ArticlesBoard moreCount={0}/>
    </div>
  );
}
