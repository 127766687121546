import React from "react";
import { Button } from "antd";
import OTPInput from "react-otp-input";

const VerifyEmailForm = ({ otp, setOtp, verifyOTP, isLoading }) => {
  return (
    <>
      <span className="signup-text auth-text">Verify your email</span>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        secureInput={false}
        renderInput={(props) => <input {...props} />}
        inputStyle="otp_class"
      />
      <Button
        className="auth-button signup-button mt-5"
        onClick={() => {
          verifyOTP();
        }}
      >
        {isLoading == true ? (
          <>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </>
        ) : (
          "Confirm"
        )}
      </Button>{" "}
    </>
  );
};

export default VerifyEmailForm;
